canvas {
    pointer-events: none;
    position: absolute;
}

.news {
    opacity: 0;
}

.news:hover {
    fill: rgb(0, 123, 255);
    opacity: 0.3;
}