.react-datepicker-wrapper input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #007bff;
  border-radius: .25rem 0rem 0rem .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-group-append {
  cursor: pointer !important;
}

.input-group-text {
  padding: 0rem .75rem !important;
}

.input-group {
  width: 40% !important;
}

label {
  cursor: pointer;
}

@media only screen and (max-width: 770px) {
  .react-datepicker-wrapper input {
    padding: .375rem .25rem;
    font-size: 0.7rem;
  }

  .react-datepicker-wrapper {
    width: 70%;
  }

  .react-datepicker__input-container {
    padding-left: 5px;
  }

  .input-group-text {
    padding: 0rem .40rem !important;
  }

  .input-group {
    width: 30% !important;
  }

  label {
    margin-bottom: auto !important;
  }
}